<template>
  <!-- Table Container Card -->
  <div>
    <add-edit-question :is-add-edit-question-sidebar-active.sync="isAddEditQuestionSidebarActive" :editdata="editdata"
      @ClickEditQuestiondata="ClickEditQuestiondata" />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="6" md="4">
            <div>
              <b-form-group label="Search Question">
                <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6" md="4" v-if="removeButtons == true">
            <b-form-group label="Select User">
              <v-select :close-on-select="true" v-model="selectUser" :options="users" label="name" input-id="add-name">
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-overlay :show="show" rounded="sm">
        <b-table :items="feedbackRetrun" responsive :fields="fields" class="mb-0" id="custom-table" :per-page="perPage"
          :current-page="currentPage">
          <template #cell(No)="data">
            <div class="d-flex align-items-center">
              <div>
                {{ data.index + 1 }}
              </div>
            </div>
          </template>
          <template #cell(preview)="data">
            <feather-icon v-if="removeButtons == true" @click="preview_question(data.item)" v-b-modal.preview-model
              height="20px" width="20px" icon="EyeIcon" />
          </template>
          <template #cell(reply)="data">
            <div class="d-flex align-items-center">
              <div>
                {{ data.item.title }}
              </div>
            </div>
          </template>
          <template #cell(questionType)="data">
            <div class="d-flex align-items-center">
              <div>
                {{ data.item.questionType }}
              </div>
            </div>
          </template>
          <template #cell(revisionStatus)="data">
            <div class="d-flex align-items-center">
              <div v-if="data.item.revisionStatus == false">
                <b-badge pill variant="danger">
                  Pending
                </b-badge>
              </div>
              <div v-else>
                <b-badge pill variant="success">
                  Approved
                </b-badge>
              </div>
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="d-flex align-items-center">
              <div>
                <div>
                  {{ dataSplitFunction(data.item.created_at).date }}
                </div>
                <div>
                  {{ dataSplitFunction(data.item.created_at).time }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(updated_at)="data">
            <div class="d-flex align-items-center">
              <div>
                <div>
                  {{ dataSplitFunction(data.item.updated_at).date }}
                </div>
                <div>
                  {{ dataSplitFunction(data.item.updated_at).time }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex align-items-center" v-if="removeButtons == true">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                </template>
                <b-dropdown-item @click="editquestiondata(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <div style="display: flex; flex-direction: row-reverse">
          <div>
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="custom-table"
              size="sm"></b-pagination>
          </div>
          <div>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
          </div>
        </div>
      </b-overlay>

      <b-modal id="preview-model" hide-footer size="lg" centered title="Question Preview">
        <div>
          <div v-for="(preview, index) in previewbackground" :key="index + '09'">
            <div v-if="preview.data == 'image'">
              <img :src="preview.values" />
            </div>
            <div v-else :style="'background: ' +
              preview.values +
              ';height: 100%;width: 100%;position: absolute;top: 0;left: 0;'
              "></div>
          </div>
          <div class="modal_content">
            <div v-for="(preview, index) in previewTitle" :key="index + '093'">
              <div v-for="(preview, index) in previewQuestionType" :key="index + '019'">
                <h3>
                  {{ preview.data }}
                </h3>
              </div>
              <h3 style="
                    text-align: center;
                    background-color: #ffa500;
                    color: white;
                    margin-left: 10%;
                    margin-right: 10%;
                    width: 80%;
                    font-size: 1.8rem;
                    height: auto;
                    border-radius: 15px;
                  ">
                {{ preview.data }}
              </h3>
            </div>
            <!-- {{ previewQuestionType }} -->

            <!-- <> -->
            <div>
              <b-row v-for="(preview, index) in previewImage" :key="index + '0'">
                <!-- {{ preview.fieldname  }}: -->
                <b-col cols="3" class="text-center text-white">
                  <img style="height: 100px; margin: 5px 0" :src="preview.data" />
                </b-col>
                <!-- <img style="width: 100px; height: 100px" :src="preview.data" /> -->
              </b-row>
            </div>
            <!-- </b-row> -->
            <!-- {{ previewObject}} -->
            <!-- <div v-for="(preview, index) in previewObject" :key="index"> -->
            <!-- {{ previewObject }} -->
            <div v-for="(preview, index) in previewArray" :key="index + '20'">
              <!-- {{ preview }} -->
              <!-- <span v-if="preview.fielddata == 'string'"> -->
              <span v-if="preview.fieldType == 'image'">
                <img style="width: 150px; height: 150px" :src="preview.data" />
              </span>
              <span v-if="preview.fieldType == 'text'">
                <h6 style="margin-left: 10px; color: green">
                  {{ preview.data }}
                </h6>
              </span>
              <!-- </span> -->
            </div>

            <b-row>
              <b-col :cols="12 / preview.data.length + 1" v-for="(preview, index) in previewObject" :key="index + '243'"
                class="text-center text-white">
                <div v-if="preview.data.fieldType == 'image'">
                  <img style="width: 150px; height: 150px" :src="preview.data.value" />
                </div>

                <!-- {{ preview.data.isCorrect }} -->
                <div v-if="preview.data.fieldType == 'text'">
                  <div style="background: black">
                    {{ preview.data.value }}
                  </div>
                  <!-- <div v-if="preview.data.isCorrect == 'false'">
                    {{ preview.data.value }}
                  </div> -->
                </div>
                <div v-if="preview.data.fieldType == 'text'">
                  <div style="background: green">
                    {{ preview.data.isCorrect }}
                  </div>
                </div>
                <div v-if="preview.data.fieldType == 'image'">
                  <div style="background: green">
                    {{ preview.data.isCorrect }}
                  </div>
                </div>
              </b-col>
            </b-row>
            <div v-for="(preview, index) in previewText" :key="index + '348'">
              <h6 v-if="preview.fieldType == 'text'">
                {{ preview.data }}
              </h6>
            </div>
          </div>
        </div>
        <div class="modal-footer d-inline-block w-100">
          <div class="d-flex justify-content-between align-items-end">
            <div style="    margin-bottom: -16px;
                margin-left: -16px;">
              <canvas id="qrcode" style="width:200px;height: 200px;"></canvas>
              <button style="margin-top: -55px;" @click="updateQuestionStatus(previewbackground)" class="btn btn-primary">
                Approve
              </button>
            </div>
            <div>
              <button @click="backquestion" class="btn btn-primary">
                back
              </button>
              <button @click="nextquestion" class="btn btn-primary ml-1">
                next
              </button>
              <button type="button" v-b-modal.modal-json class="btn btn-primary ml-1">
                Open Json
              </button>
              <button type="button" @click="closejsonmodel()" class="btn btn-secondary ml-1" data-dismiss="modal">
                Close
              </button>
            </div>
          </div>

        </div>
      </b-modal>
      <b-modal id="modal-json" size="lg" centered title="Json Preview">
        <div style="overflow: hidden">
          <textarea style="width: 100%; height: 300px" v-model="jsonShowdata"></textarea>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BOverlay,
  BFormGroup,
  BTableSimple,
  BThead,
  BFormSelect,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { BBreadcrumb } from "bootstrap-vue";
import moment from "moment";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import AddEditQuestion from "./AddEditQuestion.vue";
import QRCode from 'qrcode';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    AddEditQuestion,
    BBreadcrumb,
    BCard,
    BOverlay,
    BFormGroup,
    BAvatar,
    BRow,
    BCol,
    BFormSelect,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
    QRCode,
    ToastificationContent
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      removeButtons: false,
      table_data: {},
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      show: false,
      feedbackDatashow: {},
      currentindextable: "",
      status: ["All", "Pending", "Active", "Resolved"],
      users: [],
      selectUser: {},
      searchQuery: "",
      previewText: [],
      previewImage: [],
      previewArray: [],
      previewbackground: [],
      previewTitle: [],
      previewObject: [],
      previewQuestionType: [],
      restoreMessage: "",
      fields: [
        'No',
        { key: "preview", label: "PREVIEW" },
        { key: "title", label: "TITLE" },
        { key: "questionType", label: "QUESTIONTYPE" },
        { key: "revisionStatus", label: "REVISIONSTATUS" },

        { key: "created_at", label: "CREATEDAT" },
        { key: "updated_at", label: "UPDATEDAT" },
        { key: "action", label: "ACTION" },

      ],
      selectedStatus: "All",
      isAddNewUsersSidebarActive: false,
      isAddEditUsersSidebarActive: false,
      editDataProps: {},
      editdata: {},
      DeleteUserAlertMessage: "",
      isAddChangePasswordSidebarActive: false,
      changePasswordId: "",
      jsonShowdata: {},
      isAddEditQuestionSidebarActive: false,
      assignUserId: {
        id: "",
      },
      feedbackData: [],
      table_: [],
      saveStatusFilter: [],
      editPropsStatusActive: {
        id: "",
        status: "",
        message: "",
      },
      modelImage: "",
    };
  },
  computed: {
    feedbackRetrun() {
      this.show = true;
      let finaldata;
      if (this.searchQuery != "") {
        // console.log('searchquery---');

        finaldata = this.table_.filter((item) => {
          if (
            item.title
          ) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every(
                (v) =>
                  item.title.toLowerCase().includes(v)
              );
          }
        });
      } 
      else if (this.selectUser && this.selectUser._id != '' && this.removeButtons == true) {
        // console.log('else if');
        finaldata = this.table_.filter((item) => {
          if (
            item.editby
          ) {
            return this.selectUser._id
              .toLowerCase()
              .split(" ")
              .every(
                (v) =>
                  item.editby.toLowerCase().includes(v)
              );
          }
        });
        //
      }
       else {
        // console.log('else');

        finaldata = this.table_;
      }
      this.show = false;
      this.feedbackData = finaldata;
      return this.feedbackData;
    },
    rows() {
      // return 39;
      return this.feedbackData.length;
    },
  },
  mounted() {
    var newPermission = JSON.parse(localStorage.getItem("permission"));
    for (let index = 0; index < newPermission.permission.length; index++) {
      if (newPermission.permission[index] == "manage_revisions") {
        this.removeButtons = true;
        break;
      } else {
        this.removeButtons = false;
      }
    }
  },
  created() {
    this.getPendingRevisionQuestion();
  },
  methods: {
    dataSplitFunction(date) {
      let dateObj = {};
      let time = moment(date).local().format("hh:mm a");
      dateObj["time"] = time;
      let _date = moment(date).local().format("DD/MM/Y");
      dateObj["date"] = _date;
      return dateObj;
    },
    ClickEditQuestiondata(edit_question_data) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/editquestion",
          edit_question_data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.restoreMessage = "Question Successfully Edit";
          this.showToasted("success");
          this.getPendingRevisionQuestion();
        })
        .catch(function (error) {
          this.restoreMessage = "Try Again Question Not Edit";
          this.showToasted("danger");
        });
    },
    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.restoreMessage,
          variant,
        },
      });
    },
    updateQuestionStatus() {
      this.show = true;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/question/status",
          {
            _id: this.table_[this.currentindextable]._id,
            revisionStatus: true
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((res) => {
          this.restoreMessage = res.data.message;
          this.showToasted("success");
          this.getPendingRevisionQuestion();
          this.$root.$emit('bv::hide::modal', 'preview-model');
        })
        .catch(function (error) {
          this.restoreMessage = "Try Again Question Not Added";
          this.showToasted("danger");
        });
    },
    editquestiondata(question) {
      // console.log('question',question)
      //  question =  JSON.stringify(question);
      let questionClone = JSON.parse(JSON.stringify(question));
      // console.log('questionClone',questionClone)
      delete questionClone.userId;
      delete questionClone.editby;
      delete questionClone.questionNo;
      delete questionClone.classId;
      delete questionClone.subjectId;
      delete questionClone.categoryId;
      this.isAddEditQuestionSidebarActive = true;
      // // alert(0);
      // console.log(question);
      this.editdata = questionClone;
    },
    closejsonmodel() {
      this.new_index = "";
      this.$root.$emit("bv::hide::modal", "preview-model");
    },
    converttojson(jsondata) {
      this.jsonShowdata = JSON.stringify(jsondata, null, 2);
      // this.jsonShowdata = jsondata;
    },
    preview_question(questionObj) {
      // console.log('index',JSON.stringify(questionObj) );
      const index = this.table_.findIndex(item => item._id === questionObj._id);

      (this.previewText = []),
        (this.previewImage = []),
        (this.previewArray = []),
        (this.previewbackground = []),
        (this.previewTitle = []),
        (this.previewObject = []),
        (this.previewQuestionType = []);
      // alert(index);
      this.previewdata = this.table_[index];
      delete this.previewdata.userId;
      delete this.previewdata.editby;
      delete this.previewdata.classId;
      // delete this.previewdata.questionNo;
      delete this.previewdata.subjectId;
      delete this.previewdata.categoryId;
      //  delete this.previewdata.editby;
      this.previewdata = JSON.stringify(this.previewdata);
      this.previewdata = JSON.parse(this.previewdata.replace(/null/g, '""'));
      this.converttojson(this.previewdata);
      this.currentindextable = index;
      // console.log(this.previewdata)
      // return "done"
      // objectdata = objectdata.replace(/null/g, '""');questionType
      var a = this.previewdata;
      var changeID = a._id;
      a["question_id"] = changeID;
      var backgrounddata = a.background;
      var questionTitle = a.title;
      var backgroundType = a.backgroundType;
      // console.log("background", backgrounddata);
      delete a.title;
      var Question_Type = a.questionType;
      // delete a._id;
      delete a.backgroundType;
      delete a.backgroundImage;
      delete a.backgroundColor;
      delete a.background;
      delete a._id;
      delete a.id;
      delete a.level_id;
      delete a.questionType;
      delete a.question_id;
      delete a.updated_at;
      delete a.created_at;
      // var b = this.;
      // console.log(a);
      // return 'done';
      var arr = [];
      var arrfinal = {};
      arr.push(a);
      // return arr;
      var arrObject = [];
      var createobject = {};
      var pushallobject = [];
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "title";
      colorpickerobject["fieldType"] = "title";
      colorpickerobject["mapto"] = [];
      colorpickerobject["data"] = questionTitle;

      pushallobject.push(colorpickerobject);
      // console.log(arr);
      for (let index = 0; index < arr.length; index++) {
        for (let [key, value] of Object.entries(arr[index])) {
          // if(key !==)
          arrfinal = {};
          var newkey = "fieldname";

          arrfinal[newkey] = key;

          arrfinal["mapto"] = [];
          arrfinal["nested"] = [];
          //  console.log(key);
          if (typeof value == "string") {
            // console.log("data1", typeof(value));
            value.startsWith("http")
              ? (arrfinal["fieldType"] = "image")
              : (arrfinal["fieldType"] = "text");

            arrfinal["data"] = value;
          }
          if (typeof value == "number") {
            arrfinal["fieldType"] = "text";
            arrfinal["data"] = value;
          }
          // console.log(typeof value);
          if (typeof value == "object") {
            arrfinal["fieldType"] = "array";
            if (value.length == 0) {
              // console.log("at length 0",value);
              arrfinal["fieldType"] = "array";
              arrfinal["data"] = [];
            } else {
              for (let x = 0; x < value.length; x++) {
                createobject = {};
                var nested = "fieldname";
                var y = x + 1;
                createobject[nested] = key + "_" + y;
                createobject["mapto"] = [];
                // console.log(typeof(value[x]));
                var checkdatastart = value[x];
                if (typeof checkdatastart == "number") {
                  // console.log("number",value[x]);
                  checkdatastart = checkdatastart.toString();
                  createobject["fieldType"] = "text";
                  // createobject["fielddata"] = "string";
                } else if (typeof checkdatastart == "object") {
                  //  console.log("object",value[x]);
                  // console.log(checkdatastart);
                  checkdatastart = checkdatastart.value;
                  checkdatastart = checkdatastart.toString();
                  // console.log(checkdatastart);
                  // if (typeof checkdatastart == "string") {
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                  // } else {

                  // (createobject["fieldType"] = "text")
                  // }
                  //  console.log(checkdatastart);

                  // createobject["fielddata"] = "object";
                } else {
                  //  console.log("string",value[x]);
                  checkdatastart = checkdatastart;
                  // console.log(typeof(checkdatastart));
                  checkdatastart.startsWith("http")
                    ? (createobject["fieldType"] = "image")
                    : (createobject["fieldType"] = "text");
                  // createobject["fieldType"] = "text";
                  // createobject["fielddata"] = "string";
                }

                createobject["data"] = value[x];

                arrfinal["nested"].push(createobject);
              }
            }
          }

          pushallobject.push(arrfinal);
        }
      }
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "background";
      colorpickerobject["mapto"] = [];
      colorpickerobject["values"] = backgrounddata;
      colorpickerobject["data"] = backgroundType;
      pushallobject.push(colorpickerobject);
      var colorpickerobject = {};
      colorpickerobject["fieldname"] = "QuestionType";
      colorpickerobject["mapto"] = [];
      colorpickerobject["feildType"] = "questionType";
      colorpickerobject["data"] = Question_Type;
      pushallobject.push(colorpickerobject);
      // console.log(pushallobject);
      // console.log(pushallobject);
      for (let a = 0; a < pushallobject.length; a++) {
        if (pushallobject[a].fieldType == "image") {
          this.previewImage.push(pushallobject[a]);

          // console.log("previewText",this.previewText)
        }
        if (pushallobject[a].fieldType == "text") {
          this.previewText.push(pushallobject[a]);
          // console.log("previewImage", this.previewImage);
        }
        if (pushallobject[a].fieldType == "array") {
          //  console.log(pushallobject[a].nested)
          for (let y = 0; y < pushallobject[a].nested.length; y++) {
            if (typeof pushallobject[a].nested[y].data == "string") {
              this.previewArray.push(pushallobject[a].nested[y]);
            } else {
              // console.log("preview object array", pushallobject[a].nested[y].data.value);
              // for (let x = 0; x < pushallobject[a].nested[y].data.length; x++) {
              // var createobj = "";
              // console.log("value", pushallobject[a].nested[y].data.value);
              var data = pushallobject[a].nested[y].data.value;
              data = data.toString();
              data.startsWith("http")
                ? (pushallobject[a].nested[y].data["fieldType"] = "image")
                : (pushallobject[a].nested[y].data["fieldType"] = "text");
              this.previewObject.push(pushallobject[a].nested[y]);
              // console.log("preview object array", this.previewObject);
              // }
            }
          }

          // console.log("previewArray", this.previewArray);
          // console.log("preview object array", this.previewObject);
        }
        if (pushallobject[a].fieldType == "title") {
          this.previewTitle.push(pushallobject[a]);
          // console.log("previewTitle",this.previewTitle)
        }
        if (pushallobject[a].data == "image") {
          this.previewbackground.push(pushallobject[a]);
          // console.log("previewbackground",this.previewbackground)
        }
        if (pushallobject[a].data == "color") {
          this.previewbackground.push(pushallobject[a]);
          // console.log("previewbackground",this.previewbackground)
        }
        if (pushallobject[a].feildType == "questionType") {
          this.previewQuestionType.push(pushallobject[a]);
          // console.log("previewbackground",this.previewbackground)
        }
      }
      this.previewdata = pushallobject;
      setTimeout(() => {
        QRCode.toCanvas(document.getElementById('qrcode'), this.table_[index]._id + '--revision');
      }, 500);
    },
    selectUserFunction(i) {
      // console.log('i',i);
      this.show = true;
      // setTimeout(() => {
      if (this.selectedStatus == "All") {
        if (i.name != "All") {
          // console.log('this.searchQuery',this.searchQuery)
          let finaldata = this.table_.filter((item) => {
            if (item.userId) {
              // console.log(typeof(item.userId));
              // console.log()
              return i._id
                .toLowerCase()
                .split(" ")
                .every((v) => item.userId.toLowerCase().includes(v));
            }
          });
          // console.log('finaldata',finaldata)
          this.feedbackData = finaldata;
          this.show = false;
        } else {
          this.feedbackData = this.table_;
          this.show = false;
        }
      } else {
        if (i.name != "All") {
          let finaldata = this.saveStatusFilter.filter((item) => {
            if (item.userId) {
              // console.log(typeof(item.userId));
              // console.log()
              return i._id
                .toLowerCase()
                .split(" ")
                .every((v) => item.userId.toLowerCase().includes(v));
            }
          });
          // console.log('finaldata',finaldata)
          this.feedbackData = finaldata;
          this.show = false;
        } else {
          this.show = false;
        }
      }
    },
    selectStatusFunction(i) {
      this.show = true;
      // setTimeout(() => {
      if (i != "All") {
        // console.log('this.searchQuery',this.searchQuery)
        let finaldata = this.table_.filter((item) => {
          if (item.status) {
            // console.log(item.subjectName);
            // console.log()
            return i
              .toLowerCase()
              .split(" ")
              .every((v) => item.status.toLowerCase().includes(v));
          }
        });
        this.saveStatusFilter = finaldata;
        // console.log('finaldata',finaldata)
        this.feedbackData = finaldata;
        this.show = false;
      } else {
        this.feedbackData = this.table_;
        this.show = false;
      }
      // }, 1000);
    },
    getFeedBack() {
      this.show = true;
      if (this.selectedStatus == "All") {
        setTimeout(() => {
          if (this.searchQuery.trim().length > 0) {
            // console.log('this.searchQuery',this.searchQuery)
            let finaldata = this.table_.filter((item) => {
              if (
                item.subjectName &&
                item.className &&
                item.categoryName &&
                item.levelName
              ) {
                // console.log(item.subjectName);
                // console.log()
                return this.searchQuery
                  .toLowerCase()
                  .split(" ")
                  .every(
                    (v) =>
                      item.subjectName.toLowerCase().includes(v) ||
                      item.className.toLowerCase().includes(v) ||
                      item.categoryName.toLowerCase().includes(v) ||
                      item.levelName.toString().toLowerCase().includes(v)
                  );
              }
            });
            // console.log('finaldata',finaldata)
            this.feedbackData = finaldata;
            this.show = false;
          } else {
            this.feedbackData = this.table_;
            this.show = false;
          }
        }, 1000);
      } else {
        setTimeout(() => {
          if (this.searchQuery.trim().length > 0) {
            // console.log('this.searchQuery',this.searchQuery)
            let finaldata = this.feedbackData.filter((item) => {
              if (
                item.subjectName &&
                item.className &&
                item.categoryName &&
                item.levelName
              ) {
                // console.log(item.subjectName);
                // console.log()
                return this.searchQuery
                  .toLowerCase()
                  .split(" ")
                  .every(
                    (v) =>
                      item.subjectName.toLowerCase().includes(v) ||
                      item.className.toLowerCase().includes(v) ||
                      item.categoryName.toLowerCase().includes(v) ||
                      item.levelName.toString().toLowerCase().includes(v)
                  );
              }
            });
            // console.log('finaldata',finaldata)
            this.feedbackData = finaldata;
            this.show = false;
          } else {
            this.feedbackData = this.table_;
            this.show = false;
          }
        }, 1000);
      }
    },
    showReplies(replies) {
      this.feedbackDatashow = replies.reply;
      //  console.log('replies',this.feedbackDatashow);
      this.$root.$emit("bv::show::modal", "modal-feed-back");
    },
    modelOpen(img) {
      this.modelImage = img;
      this.$root.$emit("bv::show::modal", "modal-screenshot");
    },
    openSideBarStatusActive(id, status, assginIds) {
      this.editPropsStatusActive.id = id;
      if (assginIds != undefined) {
        this.editPropsStatusActive.assginIds = assginIds;
      } else {
        this.editPropsStatusActive.assginIds = [];
      }
      // assginIds
      this.editPropsStatusActive.status = status;
      // console.log("asfsafas", this.editPropsStatusActive);
      this.isFeedBackStatusActiveSidebarActive = true;
    },
    changeStatusFeedBack(sendActiveStatus) {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/change-feedback/status",
          sendActiveStatus,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // this.table_data = response.data;
          this.gettabledata();
          this.$forceUpdate();
          // this.$forceUpdate();
        });
    },
    backquestion() {
      if (this.currentindextable != "0") {
        this.currentindextable = this.currentindextable - 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.table_data[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_question(this.currentindextable);
      }
    },
    nextquestion() {
      if (this.currentindextable == 0) {
        // console.log(0);
        this.currentindextable = this.currentindextable + 1;
        this.new_index = this.currentindextable;
        this.previewdata = this.table_data[this.currentindextable];
        this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
        this.preview_question(this.currentindextable);
        // console.log(this.new_index);
      } else {
        var a = this.table_data;
        // console.log(a.length)
        if (this.currentindextable < a.length - 1) {
          this.currentindextable = this.currentindextable + 1;
          this.new_index = this.currentindextable;
          this.previewdata = this.table_data[this.currentindextable];
          this.jsonShowdata = JSON.stringify(this.previewdata, null, 2);
          //  console.log(this.previewdata);
          this.preview_question(this.currentindextable);
          // console.log(this.new_index);
        } else {
        }
      }
    },
    getPendingRevisionQuestion() {
      this.show = true;
      axios
        .get(process.env.VUE_APP_API_URL + "/revision-questions/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          if (get_response.data.message != "data according to user") {
            // this.addQuestionTypeHideButton = false;
            this.show = false;
          } else {
            this.table_ = get_response.data.questions;
            this.users = get_response.data.allUsers;
            this.selectUser = get_response.data.selectedOptions;
            this.show = false;
          }
          // this.feedbackData = get_response.data;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    sendPropsToLevel(classNAme, subjectName, categoryName, catgeoryid) {
      let a = JSON.stringify(classNAme);
      localStorage.setItem("className", a);
      let b = JSON.stringify(subjectName);
      localStorage.setItem("subjectName", b);
      let c = JSON.stringify(categoryName);
      localStorage.setItem("categoryName", c);
      let d = JSON.stringify(catgeoryid);
      localStorage.setItem("categoryId", d);
    },
  },
};
</script>
  
<style lang="scss" >
// .zoom{
//    object-fit:contain;
// }
// .zoom:hover {

//   -ms-transform: scale(10) !important; /* IE 9 */
//   -webkit-transform: scale(10) !important; /* Safari 3-8 */
//   transform: scale(10) !important;

// }

img.model_backgroundimage {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: -webkit-fill-available;
}

.modal_feedback_data {
  position: relative;
  z-index: 2;
  height: 400px;
}

.table td {
  padding: 1rem !important;
}

.table th {
  padding: 1rem !important;
}

.per-page-selector {
  width: 90px;
}

.padding_table {
  padding: 0.72rem 1.4rem !important;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
  
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.dropdown button {
  padding: 0 !important;
}

.dropdown-menu {
  min-width: 8rem;
  margin-top: -17px !important;
}

.dropdown a.dropdown-item {
  padding: 0px 8px;
}
</style>
  